
import { defineComponent } from '@vue/composition-api'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'CarFinishesList',
  components: {
    CreateEditCarFinish: () => import('@/components/molecules/dialog/createEditCarFinish.vue'),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 5,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    carModel: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      createEditCarFinishDialog: false,
      carFinishEdit: {},
    }
  },
  computed: {
    headersCarFinishes (): Array<any> {
      const headers: Array<any> = [{ value: 'finish', text: this.$t('car_model.form.car_finish.finish') }]
      if (!this.readonly) {
        headers.push({
          text: this.$t('form.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        })
      }

      return headers
    },
  },
  methods: {
    ...mapMutations('carModelForm', {
      addCarFinishForCarModel: 'addCarFinish',
      replaceCarFinishForCarModel: 'replaceCarFinish',
    }),
  },
})
